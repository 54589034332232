import { useState, useRef, useEffect } from 'react';

function apiRequest({uri, body = {}, files} = {}) {
  var url = 'https://api.symbolicframeworks.com' + uri;
  var requestParams = {method: 'post', mode: 'cors'};

  if (files) {
    requestParams.body = new FormData();

    files.forEach((file, f) => requestParams.body.append(f, file));

    for (var key in body) {
      var value = body[key];

      if (typeof(value) === 'object') {
        value = JSON.stringify(value);
      }

      if (typeof(value) !== 'undefined') {
        requestParams.body.append(key, value);
      }
    }
  }
  else {
    requestParams.body = JSON.stringify(body);
    requestParams.headers = {'Content-Type': 'application/json'};
  }

  return fetch(url, requestParams).then(response => response.json());
}

function evalDevScript(devScript) {
  window.require = (_from) => {
    if (_from === "react") return require("react");
    if (_from === "react-native") return require("react-native");
    if (_from === "react-dom") return require("react-dom");
    if (_from === "konva") return require("konva");
    if (_from === "react-konva") return require("react-konva");
    if (_from === "use-image") return require("use-image");
    if (_from === "lodash") return require("lodash");
    if (_from === "ts-whammy") return require("ts-whammy");
    if (_from === "color") return require("color");
    if (_from === "react-color") return require("react-color");
  }

 var classes = new Proxy({}, {get(target, prop) {return prop;}});

  return eval(devScript);
}

if (typeof(window) !== "undefined") {
  var __console_error = console.error;
  var __lastErrorText;

  console.error = (error) => {
    if (typeof(error) === 'object' && error.stack.includes('<anonymous>:')) {
      var errorParts = error.stack.split('<anonymous>:');
      var errorLine = errorParts[1].split(':')[0];
      var errorColumn = errorParts[1].split(':')[1];

      var errorLine = parseInt(errorLine);
      var errorColumn = parseInt(errorColumn);

      var errorText = `${error.message}\n\nAn error occured in the script at line ${errorLine}, column ${errorColumn}:\n\n`;

      //get line in devScript error occurred on + 5 surrounding lines above and below
      var devScriptLines = window.devScript.split('\n');
      var errorLineIndex = errorLine - 1;

      for (var i = errorLineIndex - 5; i < errorLineIndex + 5; i++) {
        if (i === errorLineIndex) {
          errorText += `> ${devScriptLines[i]}\n`;
        }
        else {
          errorText += `  ${devScriptLines[i]}\n`;
        }
      }

      if (errorText !== __lastErrorText) {
        window.parent?.postMessage({devError: true, errorText}, '*');

        __console_error(errorText);

        __lastErrorText = errorText;
      }
    }
    else {
      console.log(error);
    }
  }
}

export default function DevApp({...props}) {
  var [app, setApp] = useState(null);

  useEffect(() => {
    (async () => {
      var {data: {appBranch}} = await apiRequest({uri: '/scaffolding/get-app', body: {hostname: window.location.hostname, server: true}});

      window.devScript = appBranch.devScript;

      var {__App} = evalDevScript(appBranch.devScript);

      setApp(<__App />);
    })();
  }, []);

  useEffect(() => {
    if (typeof(window) !== 'undefined') {
      var handleMessage = (event) => {
        if (event.data.devScript) {
          window.devScript = event.data.devScript;

          var {__App} = evalDevScript(event.data.devScript);

          setApp(<__App {...props}/>);
        }
      };

      window.addEventListener('message', handleMessage);
    }

    var pathname;
    var activeElementTagName;

    var interval = setInterval(() => {
      if (window.location.pathname !== pathname) {
        pathname = window.location.pathname;

        window.parent?.postMessage({pathChanged: pathname}, '*');
      }

      if (activeElementTagName !== document.activeElement?.tagName || null) {
        activeElementTagName = document.activeElement?.tagName || null;

        window.parent?.postMessage({activeElementTagName}, '*');
      }
    }, 50);

    return () => {
      if (typeof(window) !== 'undefined') {
        window.removeEventListener('message', handleMessage);
      }

      clearInterval(interval);
    };
  }, []);

  return app;
}

